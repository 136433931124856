.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    .loading & {
      opacity: 1;
    }
  }

  &--default {
    background-color: #4b8af9;
    border: 1px solid #4b8af9;
    color: #fff;
  }

  + .btn {
    margin-top: 15px;
  }
}

.auth {
  width: 405px;
  padding: 59px 39px 39px;
  border: 1px solid #eaebf2;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 #eaebf2;
  text-align: center;
  position: relative;
  overflow-y: auto;

  &__close-btn {
    display: inline-block;
    position: fixed;
    top: 60px;
    right: 50px;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    border: 1px solid #b7bdd2;
    box-sizing: border-box;
    color: transparent;
    font-size: 0;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      left: 16px;
      top: 8px;
      content: ' ';
      height: 18px;
      width: 2px;
      background-color: #a6abb4;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.loader {
  $fill: #fff;
  $size: 16px;
  $border: 2px;
  width: $size;
  height: $size;
  border-radius: 50%;
  border-top: $border solid transparent;
  border-right: $border solid $fill;
  border-bottom: $border solid $fill;
  border-left: $border solid $fill;
  transform: translateZ(0);
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

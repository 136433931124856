.input-group {
  position: relative;
  margin-bottom: 15px;

  &__placeholder {
    position: absolute;
    top: 8px;
    left: 15px;
    pointer-events: none;
    font-size: 14px;
    color: #8d94ad;
  }

  &__field {
    height: 60px;
    padding: 30px 15px 10px;
    width: 100%;
    color: #000;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 1px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #eaebf2;
    box-sizing: border-box;
    box-shadow: none;
    appearance: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    .has-error & {
      border-color: #ffe6e5;

      + .input-group__placeholder {
        color: #ed4037;
      }
    }
  }

  .loading & {
    opacity: 0.5;
  }
}

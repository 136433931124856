@media screen and (max-width: 414px) {
  .main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: initial;
    justify-content: flex-start;
  }

  .auth {
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    &__close-btn {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-bottom: 25px;
    background-color: #fff;
    position: relative;

    .has-error & {
      margin-bottom: 20px;
    }
  }

  .btn {
    border-radius: 0;
  }

  .back-btn {
    height: 100%;
  }

  .title {
    font-size: 16px;
    margin-bottom: 0;

    .has-error & {
      margin-bottom: 0;
    }
  }

  .repeat-sms-code-request {
    color: #4b8af9;
  }

  .input-group {
    &__placeholder {
      left: 20px;
    }

    &__field {
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 0;
      border-color: #fff;
      border-left: 0;
      border-right: 0;
    }
  }

  .message {
    margin: 0 20px 20px;
  }
}

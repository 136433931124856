.message {
  font-size: 14px;
  margin-bottom: 20px;

  &--info {
    color: #818c9d;
  }

  &--error {
    display: inline-block;
    padding: 5px 20px;
    color: #ed4037;
    border-radius: 52px;
    background-color: #ffe6e5;
  }
}

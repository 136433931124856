html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

.main {
  background-color: #f1f3f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.back-btn {
  width: 48px;
  height: 54px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  font-size: 0;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  margin: 0;
  padding: 0;

  &::after {
    $side: 12px;
    $fill: #b7bdd2;
    content: '';
    display: inline-block;
    border-right: 2px solid $fill;
    border-bottom: 2px solid $fill;
    width: $side;
    height: $side;
    margin-left: ($side * 0.5);
    transform: rotate(-225deg);
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #212c3a;
  line-height: 1.5;
  margin-bottom: 20px;

  &--main {
    line-height: 1.64;
    margin-bottom: 30px;
  }

  .has-error & {
    margin-bottom: 15px;
  }
}

.repeat-sms-code-request {
  font-size: 14px;
  color: #4b8af9;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &.disabled {
    color: #989fb5;
    pointer-events: none;
  }
}
